import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App" style={{
      backgroundColor: '#FEFEFE',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '100vh'
    }}>
      <img 
        className="nav-bar_logo"
        src={logo}
        alt="logo"
        width="300"
      />
      <h2
        style={{
          backgroundColor: '#606060',
          width: '100vw',
          paddingTop: '20px',
          paddingBottom: '20px',
          color: 'white',
          fontFamily: 'Garamond, serif'
        }}
      >
        Site Under Construction
      </h2>
    </div>
  );
}

export default App;
